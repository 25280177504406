export const URLS = {
  lovModelLinesSearch: '/lov/model-lines/search',
  lovExtendedModelsSearch: '/lov/extended-models/search',
  lovModelsSearch: '/lov/models/search',
  lovEnginesSearch: '/lov/engines/search',
  lovCarFeaturesSearch: '/lov/car-feature-items/search',
  lovCarFeaturesSections: '/lov/car-feature-sections',
  lovCarFeaturesCategories: '/lov/car-feature-categories',
  lovBranchesSearch: '/lov/branches/search',
  lovTransmissions: '/lov/transmissions',
  lovFuelTypes: '/lov/fuel-types',
  lovDrives: '/lov/drives',
  lovMakes: '/lov/makes',
  lovCarTypes: '/lov/car-types',
  lovBodies: '/lov/bodies',
  lovColors: '/lov/colors',
  lovCountries: '/lov/countries',
  lovBonusesMaluses: '/lov/bonuses-maluses',

  lovTechnicalSections: '/lov/technical-condition-sections',
  lovTechnicalCategories: '/lov/technical-condition-categories/search',
  lovTechnicalSubCategories: '/lov/technical-condition-subcategories/search',
  lovTechnicalItems: '/lov/technical-condition-items/search',
  lovStandardRepairs: '/lov/standard-repairs/search',

  employeesLogin: '/employees/login',
  employeesTokenRefresh: '/employees/tokens/refresh',

  auctionsBid: (auctionId?: string) => `/auctions/${auctionId}/bids`,
  searchAuctionsBid: '/auctions/bids/search',
  auctionDiff: (auctionId?: string) => `/auctions/${auctionId}/diff`,

  partnersTokenRefresh: '/partners/tokens/refresh',
  partnersLogin: '/partners/login',

  auctionSearch: `/auctions/search`,
  auctionSearchAgg: `/auctions/search-agg`,
  auctionDetail: (id?: string) => `/admin/auctions/${id}`,
  auctionEnd: (id: string) => `/admin/auctions/${id}/end`,
  carSearch: '/cars/search',
  carDetail: (id?: string) => `/admin/cars/${id}`,
  carMedia: '/admin/cars/media/search',
  carTechnicalSections: '/admin/cars/technical-condition-sections/search-agg',
  carTechnicalCategories:
    '/admin/cars/technical-condition-categories/search-agg',
  carTechnicalSubCategories:
    '/admin/cars/technical-condition-subcategories/search-agg',
  carTechnicalConditionItemPhotos:
    '/admin/cars/technical-condition-items-photos/search',
  carTechnicalSubCategoryPhotos:
    '/admin/cars/technical-condition-subcategory-photos/search',
  carTechnicalItems: '/admin/cars/technical-condition-items/search',
  carAccident: (id?: string) => `/admin/cars/${id}/accident`,
  carWheels: (id?: string) => `/admin/cars/${id}/wheels`,
  carCosts: (id?: string) => `/admin/cars/${id}/costs`,
  filesSearch: '/admin/files/search',
  partnersSearch: '/partners/search',
  carAdminBonusesMaluses: (carId?: string) =>
    `/admin/cars/${carId}/bonuses-maluses`,
  employeesSearch: `/employees/search`,
  opportunityNotesSearch: `/admin/opportunities/notes/search`,
  opportunityNote: (opportunityId: string) =>
    `/admin/opportunities/${opportunityId}/notes`,

  changeAuctionWinner: (auctionId?: string) =>
    `/admin/auctions/${auctionId}/change-winner`
}

export const UNKNOWN_VALUE = 'UNKNOWN_VALUE_FE_ONLY'
