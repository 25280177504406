import { css } from '@emotion/react'
import { useState, useCallback, useEffect, ReactNode } from 'react'
import { AnimateSharedLayout, AnimatePresence, motion } from 'framer-motion'

import IconClose from 'driverama-core/images/icons/IconClose.svg'
import IconSuccess from 'driverama-core/images/icons/IconSuccess.svg'
import IconError from 'driverama-core/images/icons/IconError.svg'
import { size } from 'driverama-core/styles/spacing'

import { ToastBindInterface, ToastProps } from './Toast.types'
import { SToastWrapper } from './Toast.styled'
import { inset } from 'driverama-core/styles/inset'
import { Flex } from 'driverama-core/components/Flex'
import { zIndex } from 'driverama-core/styles/variables'

function ToastCloseButton(props: {
  duration: number | undefined
  onClose?: () => void
  className?: string
}) {
  return (
    <div
      css={css`
        position: relative;
        cursor: pointer;
      `}
      className={props.className}
      onClick={props.onClose}
    >
      <svg
        viewBox="0 0 50 50"
        css={css`
          width: 32px;
          height: 32px;
          display: block;
        `}
      >
        {(props.duration ?? 0) > 0 && (
          <>
            <motion.path
              fill="none"
              strokeWidth="4"
              stroke="var(--close-timeout-fg)"
              d="M5 25C5 30.3043 7.10714 35.3914 10.8579 39.1421C14.6086 42.8929 19.6957 45 25 45C30.3043 45 35.3914 42.8929 39.1421 39.1421C42.8929 35.3914 45 30.3043 45 25C45 19.6957 42.8929 14.6086 39.1421 10.8579C35.3914 7.10714 30.3043 5 25 5C19.6957 5 14.6086 7.10714 10.8579 10.8579C7.10714 14.6086 5 19.6957 5 25Z"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{
                duration: (props.duration ?? 0) / 1000,
                stiffness: 400,
                damping: 90
              }}
              onAnimationComplete={props.onClose}
              style={{ pathLength: 1, rotate: 90, scaleX: -1 }}
            />
          </>
        )}
      </svg>
      <Flex
        variant="row"
        align="center"
        justify="center"
        css={css`
          position: absolute;
          ${inset(0)}
        `}
      >
        <IconClose />
      </Flex>
    </div>
  )
}

function Toast(props: {
  variant: 'success' | 'error'
  children?: ReactNode
  duration?: number
  onClose?: () => void
}) {
  return (
    <SToastWrapper
      variants={{
        closed: { minHeight: 0, height: 0 },
        open: { minHeight: size(10), height: 'auto' }
      }}
      layout
      variant={props.variant}
      initial="closed"
      animate="open"
      exit="closed"
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <span css={{ flex: 1 }} />

      <motion.div
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 }
        }}
      >
        <Flex variant="row" gap={4} align="center">
          {props.variant === 'success' && <IconSuccess />}
          {props.variant === 'error' && <IconError />}
          <div css={{ paddingTop: size(1), paddingBottom: size(1) }}>
            {props.children}
          </div>
        </Flex>
      </motion.div>

      <motion.div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          flex: 1
        }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 }
        }}
      >
        <ToastCloseButton duration={props.duration} onClose={props.onClose} />
      </motion.div>
    </SToastWrapper>
  )
}

export function ToastProvider({
  bindNotify
}: {
  bindNotify: (notify: ToastBindInterface) => void
}) {
  const [toast, setToast] = useState<ToastProps>()

  const closeToast = useCallback((id: string) => {
    setToast(undefined)
  }, [])

  const createNotification = useCallback((props: ToastProps) => {
    setToast(props)
  }, [])

  // const removeAll = useCallback(() => setToast([]), [])

  useEffect(() => {
    bindNotify({
      closeToast,
      createNotification
    })
  }, [bindNotify, closeToast, createNotification])

  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: ${zIndex('notification')};
        pointer-events: none;
      `}
    >
      <AnimateSharedLayout>
        <AnimatePresence>
          {toast && (
            <Toast
              key={toast.id}
              variant={toast.type}
              duration={toast.duration}
              onClose={() => closeToast(toast.id ?? `${toast.type}_0`)}
            >
              {toast.content}
            </Toast>
          )}
        </AnimatePresence>
      </AnimateSharedLayout>
    </div>
  )
}
