import { toast } from 'driverama-core/components/toast/Toast'
import { useEffect, useRef, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useCopyToClipboard as useCopy } from 'react-use'

export function useIsMounted() {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return isMounted
}

export function useIsMountedRef() {
  const mounted = useRef<boolean>(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  return mounted
}

type UseCarouselProps = {
  rootMarginLeft?: number
  rootMarginRight?: number
  mounted?: boolean
}

export function useCarousel<T extends HTMLElement, U extends HTMLElement>(
  props?: UseCarouselProps
) {
  const { rootMarginLeft = 0, rootMarginRight = 0 } = props || {}

  const childRefs = useRef<T[]>([])
  const containerRef = useRef<U | null>(null)
  const observerRef = useRef<IntersectionObserver>(null)

  const callbackRef = useRef<(entries: IntersectionObserverEntry[]) => void>()
  const pageStateRef = useRef<Record<string, boolean>>({})
  const [pageState, setPageState] = useState<Record<string, boolean>>({})

  useEffect(() => {
    callbackRef.current = entries => {
      const commitObj: Record<string, boolean> = {}
      for (const entry of entries) {
        if (entry.target instanceof HTMLElement) {
          const key = entry.target.dataset.page ?? ''
          commitObj[key] = entry.isIntersecting
        }
      }

      const newState = { ...pageStateRef.current, ...commitObj }
      if (Object.values(newState).some(Boolean)) {
        setPageState(newState)
      }

      pageStateRef.current = newState
    }
  })

  useEffect(() => {
    observerRef.current?.disconnect()
    if (!containerRef.current) return

    const observer =
      observerRef.current ??
      new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          callbackRef.current?.(entries)
        },
        {
          root: containerRef.current,
          threshold: 0.9,
          rootMargin: `0px ${rootMarginRight}px 0px ${rootMarginLeft}px`
        }
      )

    for (const node of childRefs.current) {
      if (node) observer.observe(node)
    }

    return () => observer.disconnect()
  }, [rootMarginLeft, rootMarginRight, props?.mounted])

  const handleArrowClick = (diff: number) => {
    if (!containerRef.current) return
    const itemWidth = childRefs.current
      ?.find(item => item)
      ?.getBoundingClientRect()?.width

    if (!itemWidth) return

    containerRef.current.scrollBy({
      left: diff * itemWidth,
      behavior: 'smooth'
    })
  }

  return { childRefs, containerRef, pageState, handleArrowClick }
}

export function useScrollToError(errors: FieldErrors) {
  useEffect(() => {
    if (errors) {
      const errorsValues = Object.keys(errors)

      if (errorsValues.length > 0) {
        const elements = document.getElementsByName(errorsValues[0])

        if (elements && elements[0]) {
          elements[0].scrollIntoView({ behavior: 'smooth' })
        } else {
          // try find element by id
          const element = document.getElementById(errorsValues[0])
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
          }
        }
      }
    }
  }, [errors])
}

export function useValidateDefaultValues(
  defaultFormValues: Record<string, unknown> | undefined,
  trigger: () => void
) {
  useEffect(() => {
    if (defaultFormValues !== undefined) {
      trigger()
    }
  }, [defaultFormValues, trigger])
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T | undefined>()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export function useBlob(blob?: Blob) {
  const [url, setUrl] = useState<string | undefined>(undefined)
  const isMounted = useIsMountedRef()

  useEffect(() => {
    const url = blob ? URL.createObjectURL(blob) : undefined
    if (isMounted.current) setUrl(url)
    return () => {
      if (url) {
        URL.revokeObjectURL(url)
      }
    }
  }, [blob, isMounted])

  return url
}

interface useKeyDownOptions {
  preventDefault?: boolean
}

export function useKeyDown(
  key: string,
  callback: () => void,
  options?: useKeyDownOptions
) {
  const ref = useRef<() => void>()
  useEffect(() => {
    ref.current = callback
  }, [callback])

  useEffect(() => {
    function listener(ev: KeyboardEvent) {
      if (ev.code === key) {
        if (options?.preventDefault) {
          ev.preventDefault()
        }
        ref.current?.()
      }
    }

    window.addEventListener('keydown', listener)
    return () => void window.removeEventListener('keydown', listener)
  }, [key, options])
}

export function useSafariCheck() {
  const [safari, setSafari] = useState(false)

  useEffect(() => {
    setSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent))
  }, [])

  return safari
}

export const useIsFocused = () => {
  const focused = useRef<boolean>(false)

  const setFocused = (newState: boolean) => {
    if (focused.current) {
      focused.current = newState
    }
  }

  return [focused.current, setFocused] as const
}

export const useCopyToClipboard = () => {
  const [, copy] = useCopy()

  const copyToClipboard = (text: string, message?: string) => {
    copy(text)
    toast({
      type: 'success',
      content: message || 'Text copied'
    })
  }

  return { copyToClipboard }
}
