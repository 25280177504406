import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { size } from 'driverama-core/styles/spacing'
import { shadow, font, color } from 'driverama-core/styles/variables'
import { getResponsiveStyles } from 'driverama-core/styles/responsivity'

export const SToastWrapper = styled(motion.div)<{
  variant: 'success' | 'error'
}>`
  box-shadow: ${shadow(1)};

  font-family: ${font('text')};
  font-weight: 300;

  width: 100%;
  min-height: ${size(10)};
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 ${size(2)};

  pointer-events: all;

  ${({ variant }) =>
    getResponsiveStyles(variant, {
      success: css`
        --close-timeout-fg: ${color('night-l-650')};
        --close-timeout-bg: ${color('night-l-300')};

        background-color: ${color('night-l-100')};
        color: ${color('white')};
      `,
      error: css`
        --close-timeout-fg: ${color('red', 0.7)};
        --close-timeout-bg: hsl(358, 57%, 50%);

        background-color: ${color('warning')};
        color: ${color('white')};
      `
    })}
`
