import styled from '@emotion/styled'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color } from 'driverama-core/styles/variables'

export const Layout = styled.div`
  position: relative;
  height: 100vh;
  display: grid;
  grid-template-rows: ${size(22)} ${size(22)} 0;

  @media ${media.lte('tablet')} {
    overflow-x: hidden;
    grid-template-rows: ${size(18)} ${size(22)} 0 auto;
  }

  @media ${media.lte('mobile')} {
    grid-template-rows: ${size(18)} ${size(20)} 0 auto;
  }

  @media print {
    display: block;
    height: auto;
  }
`

export const Main = styled.main`
  overflow: auto;
  padding-left: ${size(92)};
  background-color: ${color('night-l-800')};

  @media ${media.lte('tablet')} {
    padding-left: 0;
  }
`
