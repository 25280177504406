import { ToastProvider } from './ToastProvider'
import { render } from 'react-dom'
import { hasWindow } from 'driverama-core/utils/dom'
import { ToastBindInterface, ToastProps } from './Toast.types'
import { captureException } from '@sentry/nextjs'

const PORTAL_ID = 'cleevio-toast'

export class Toaster {
  interface?: ToastBindInterface
  toastCounter = 0
  onError!: (err: unknown | null) => void

  constructor(onError: (err: unknown | null) => void) {
    if (!hasWindow()) return

    let portalElement
    const existingPortalElement = document.getElementById(PORTAL_ID)

    this.onError = onError

    if (existingPortalElement) {
      portalElement = existingPortalElement
    } else {
      const el = document.createElement('div')
      el.id = PORTAL_ID
      el.className = 'Toaster'
      document.body?.appendChild(el)

      portalElement = el
    }

    render(<ToastProvider bindNotify={this.bindNotify} />, portalElement)
  }

  close = (id: string) => this.interface?.closeToast(id)

  bindNotify = (bind: ToastBindInterface) => void (this.interface = bind)

  notify = async (props: ToastProps) => {
    if (props.type === 'error' && props.error) this.onError(props.error)

    this.interface?.createNotification({
      ...props,
      id: props.id ?? `${props.type}_${this.toastCounter++}`,
      duration: props.duration ?? 5000
    })
  }
}

const onError = (error: unknown | null) => {
  captureException(error)
}

const toaster = new Toaster(onError)
export const toast = toaster.notify
