import { TextBody } from 'driverama-core/components/text/Text'
import { ComponentProps } from 'react'
import { color } from 'driverama-core/styles/variables'

type Props = ComponentProps<typeof TextBody>

export function ErrorMessage(props: Props) {
  return (
    <TextBody variant="caption" css={{ color: color('warning') }} {...props}>
      {props.children}
    </TextBody>
  )
}
